import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector, useDispatch } from "react-redux";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
} from "@mui/material";
import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMediaQuery } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: (props) => props.maxHeight || "none",
    overflowY: "auto",
  },
  thead: {
    backgroundColor: "#5135EF",
    color: "white",
  },
}));

const RATING_OPTIONS = [
  "Basic",
  "Intermediate",
  "Functional",
  "Proficient",
  "Expert",
];

const ServiceList = () => {
  const { currentPage } = useSelector((state) => state.nameReducer);
  const list = useSelector((state) => state.nameReducer.selectedService);
  const [selectedValue, setSelectedValue] = useState(list);
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [rateChartOpen, setRateChartOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // const [tableHeight, setTableHeight] = useState('587px'); // Initial dynamic height
  const classes = useStyles({ maxHeight: "calc(100vh - 250px)" });
  const dispatch = useDispatch();
  const getCommentData = (data) => {
    if (data.length > 30) {
      return <Tooltip title={data}>{data.slice(0, 30)}...</Tooltip>;
    }
    return data;
  };
  const handleRateChartOpen = () => {
    setRateChartOpen(true);
  };

  const handleRateChartClose = () => {
    setRateChartOpen(false);
  };
  const handleOpen = (index) => {
    setActiveIndex(index);
    setComment(selectedValue[index]["description"]);
    setOpen(true);
  };

  const handleClose = () => {
    setActiveIndex(null);
    setOpen(false);
  };

  const handleChange = (event, index) => {
    let ar = [...selectedValue];
    ar[index]["rating"] = event.target.value;
    setSelectedValue(ar);
    dispatch({ type: "update_service", data: ar });
  };

  const createData = (name, calories, fat, carbs, protein, x, description) => {
    return { name, calories, fat, carbs, protein, x, description };
  };

  const rows = selectedValue.map((data) => {
    return createData(data.service_name, 1, 2, 3, 4, 5, data.description);
  });

  const setCommentData = () => {
    let ar = [...selectedValue];
    // console.log(comment, '---', ar, activeIndex)
    ar[activeIndex]["description"] = comment.trim();
    // console.log('------asdfsdlnsdl',comm, comment,ar, typeof(comment))
    setSelectedValue(ar);
    dispatch({ type: "update_service", data: ar });
    setComment("");
    setOpen(false);
  };
  const onBlurComment = (comment, index) => {
    // setComment(selectedValue[index]["description"]);
    let ar = [...selectedValue];
    // console.log(comment, '-blurrr--', ar, index, comment)
    ar[index]["description"] = comment.trim();
    // console.log('------asdfsdlnsdl',comm, comment,ar, typeof(comment))
    setSelectedValue(ar);
    dispatch({ type: "update_service", data: ar });
    setComment("");
  }
  const setCommentMobile = (comment, index) => {
    let ar = [...selectedValue];
    ar[index]["description"] = comment;
    dispatch({ type: "update_service", data: ar });
  };
  const handlePrevious = async () => {
    dispatch({ type: "page", data: currentPage - 1 });
  };
  const handleNext = () => {
    if (selectedValue.some((data) => data.rating === "")) {
      setShowModal(true);
      return;
    }
    dispatch({type:"page",data:currentPage+1})
  };

  return (
    <>
      <Box
        style={{ height: "inherit", display: "flex", justifyContent: "center" }}
      >
        <Container
          style={{
            width: "100%",
            marginLeft: "0px",
            marginRight: "0px",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            style={{
              marginBottom: "0px",
              paddingBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Rate your skills
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            component="h3"
            sx={{ marginBottom: "10px" }}
          >
            Click{" "}
            <span
              style={{
                textDecoration: "underline",
                color: "#5135EF",
                cursor: "pointer",
                transition: "background-color 0.3s",
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#f0e6ff")}
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = "transparent")
              }
              onClick={handleRateChartOpen}
            >
              here
            </span>{" "}
            to know more.
          </Typography>
          <div
            style={{
              height: "calc(100% - 33px)",
              position: "relative",
            }}
          >
            {isMobile ? (
              <Box
                style={{
                  boxShadow:
                    "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
                  padding: "20px",
                  marginTop: "20px",
                  backgroundColor: "white",
                  borderRadius: "8px",
                }}
              >
                {rows.map((row, index) => (
                  <Box key={index} marginBottom={2}>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                      <Typography
                        variant="body1"
                        style={{ marginRight: "8px" }}
                      >
                        {index + 1}.
                      </Typography>
                      <Typography variant="body1">
                        Please Rate Your Skill Level with {row.name}.
                      </Typography>
                    </div>
                    <div style={{ marginLeft: "20px", marginTop: "10px" }}>
                      {RATING_OPTIONS.map((rating, ind) => (
                        <FormControlLabel
                          style={{ width: "100%" }}
                          key={ind}
                          label={
                            <Typography variant="body2">{rating}</Typography>
                          }
                          control={
                            <Radio
                              checked={
                                selectedValue[index]["rating"] ===
                                (ind + 1).toString()
                              }
                              onChange={(event) => handleChange(event, index)}
                              value={(ind + 1).toString()}
                              name={"radio-buttons" + index}
                            />
                          }
                        />
                      ))}
                      <div
                        style={{
                          display: "flex",
                          paddingBottom: "10px",
                        }}
                      >
                        <TextField
                          fullWidth
                          multiline
                          label="Comment (Optional)"
                          name="experience"
                          variant="standard"
                          value={
                            row.description
                              ? row.description
                              : ""
                          }
                          onChange={(e) =>
                            setCommentMobile(e.target.value, index)
                          }
                          onBlur = {(e) =>{
                            onBlurComment(e.target.value, index)
                          }}

                          margin="normal"
                        />
                      </div>
                    </div>
                    {index < rows.length - 1 && (
                      <Divider sx={{ marginTop: 1 }} />
                    )}
                  </Box>
                ))}
              </Box>
            ) : (
              <div
                style={{ maxHeight: "calc(100% - 75px)", overflowY: "hidden" }}
                id="tableContainer"
              >
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table stickyHeader aria-label="serviceTable">
                    <TableHead>
                      <TableRow>
                        <TableCell className="serviceThead"></TableCell>
                        <TableCell
                          className="serviceThead"
                          align="center"
                          style={{ fontSize: "medium", fontWeight: "bold" }}
                        >
                          Basic
                        </TableCell>
                        <TableCell
                          className="serviceThead"
                          align="center"
                          style={{ fontSize: "medium", fontWeight: "bold" }}
                        >
                          Intermediate
                        </TableCell>
                        <TableCell
                          className="serviceThead"
                          align="center"
                          style={{ fontSize: "medium", fontWeight: "bold" }}
                        >
                          Functional
                        </TableCell>
                        <TableCell
                          className="serviceThead"
                          align="center"
                          style={{ fontSize: "medium", fontWeight: "bold" }}
                        >
                          Proficient
                        </TableCell>
                        <TableCell
                          className="serviceThead"
                          align="center"
                          style={{ fontSize: "medium", fontWeight: "bold" }}
                        >
                          Expert
                        </TableCell>
                        <TableCell
                          className="serviceThead"
                          align="left"
                          style={{
                            width: "200px",
                            fontSize: "medium",
                            fontWeight: "bold",
                          }}
                        >
                          Comment
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="center">
                            <Radio
                              checked={selectedValue[index]["rating"] === "1"}
                              onChange={(event) => handleChange(event, index)}
                              value="1"
                              name={"radio-buttons" + index}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Radio
                              checked={selectedValue[index].rating === "2"}
                              onChange={(event) => handleChange(event, index)}
                              value="2"
                              name={"radio-buttons" + index}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Radio
                              checked={selectedValue[index].rating === "3"}
                              onChange={(event) => handleChange(event, index)}
                              value="3"
                              name={"radio-buttons" + index}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Radio
                              checked={selectedValue[index].rating === "4"}
                              onChange={(event) => handleChange(event, index)}
                              value="4"
                              name={"radio-buttons" + index}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Radio
                              checked={selectedValue[index].rating === "5"}
                              onChange={(event) => handleChange(event, index)}
                              value="5"
                              name={"radio-buttons" + index}
                            />
                          </TableCell>
                          <TableCell align="left" style={{ width: "200px" }}>
                            {row.description ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div style={{ wordBreak: "break-all" }}>
                                  {getCommentData(row.description)}
                                </div>
                                <div>
                                  <IconButton
                                    onClick={() => {
                                      handleOpen(index);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </div>
                              </div>
                            ) : (
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                  handleOpen(index);
                                }}
                              >
                                Add
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", py: 3 }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handlePrevious}
                style={{ width: "102px" }}
              >
                Previous
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleNext}
                style={{ width: "102px" }}
              >
                Next
              </Button>
            </Box>
          </div>
        </Container>
      </Box>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          maxWidth={"xs"}
          fullWidth={true}
        >
          <DialogTitle style={{ paddingBottom: "0px" }} id="modal-title">
            Comment
          </DialogTitle>
          <DialogContent style={{ paddingTop: "20px" }}>
            <DialogContentText>
              <div>
                <TextField
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                  sx={{ width: "100%" }}
                  id="outlined-multiline-static"
                  label="Comment"
                  multiline
                  rows={4}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={setCommentData}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={showModal}
          onClose={() => setShowModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Rating is required of all AWS Services.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowModal(false)}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={rateChartOpen}
          onClose={handleRateChartClose}
          aria-labelledby="rate-chart-title"
          maxWidth="md"
        >
          <DialogTitle id="rate-chart-title">Rating System</DialogTitle>
          <DialogContent>
            <List>
              <ListItem>
                <ListItemText
                  primary="Basic"
                  secondary="You have a basic understanding but have not applied it in a project."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Intermediate"
                  secondary="You've applied this knowledge in small projects or tasks."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Functional"
                  secondary="You can use this skill for most tasks, but might need help with complex scenarios."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Proficient"
                  secondary="You've used this skill in varied situations and can handle advanced challenges."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Expert"
                  secondary="You have a deep understanding, and colleagues often come to you for guidance and expertise."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Add Comment"
                  secondary="Please add a brief about how you used these services in the project in the comment column."
                />
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRateChartClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ServiceList;
