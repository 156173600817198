import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios';
import {
    Container,
    Typography,
    Box,
    TextField,
    MenuItem,
    Button,
  } from "@mui/material";
  import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from "@mui/material";
import { toast } from 'react-toastify';
import { useMediaQuery } from "@mui/material";

const ExpSummary = ()=> {
    let userdata=useSelector(state => state.nameReducer.user);
    const selectedServices=useSelector( (state) => state.nameReducer.selectedService)
    const {currentPage}=useSelector(state => state.nameReducer);
    const dispatch = useDispatch();
    const [user, setUser] = useState(userdata);
    const [errors, setErrors] = useState({});
    const [showConfirm,setShowConfirm]=useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));


    const validate = (formData) => {
        let formErrors = {};
        // console.log('1')
        // Validation for experience
        if (!formData.experience || formData.experience <= 0) {
            formErrors.experience = "Experience must be a number greater than 0";
        }
        // console.log(2)
        // Validation for summary
        if (!formData.summary) {
            formErrors.summary = "Summary is required";
        }
        // console.log(3)
        setErrors(formErrors);
        return formErrors;
    };

    const handleCloseConfirm = () =>{
        setShowConfirm(false);
      }
    const finalSubmit = async () =>{
        try {
          setShowConfirm(false)
          dispatch({ type: "loader", data: true});
          const api_url = 'https://o7zcfdvf5h.execute-api.us-east-1.amazonaws.com/save_survey';
          let body={...user,aws_services_ratings:selectedServices}
        //   console.log(body)
          const apiResponse = await axios.post(api_url,body);
          dispatch({ type: "loader", data: false});
          // toast.success(apiResponse.data.message.toString(),{
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          //   });
    
          dispatch({ type: "page" ,data:currentPage+1 });
        } catch (err) {
          dispatch({ type: "loader", data: false});
          toast.error(err.message.toString(), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
       }  
    const handleSubmit = (e) => {
        e.preventDefault();
        if (user.summary){
            user.summary = user.summary.trim()
        }
        // console.log(user)
        const formErrors = validate(user);
        setErrors(formErrors);
        if (Object.keys(formErrors).length === 0) {
            // dispatch({type:"page",data:currentPage+1})
            dispatch({ type: "userUpdate", data: user})
            setShowConfirm(true)
        }
        
    };

    const handlePrevious = async () => {
        dispatch({type:"page",data:currentPage-1})
       }
    return (
      <>
        <Container
          maxWidth="md"
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          <Box
            style={{
              boxShadow:
                "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
              padding: "20px",
              marginTop: "80px",
              backgroundColor: "white",
              borderRadius: "16px",
            }}
          >
            <Typography
              variant={isMobile ? "h6" : "h4"}
              align="center"
              gutterBottom
            >
              Overall AWS Experience
            </Typography>
            <form name="form-signup" id="form-signup" onSubmit={"handleNext"}>
              <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                <TextField
                  fullWidth
                  placeholder="Enter your experience in years"
                  label="Total years of experience with AWS*"
                  name="experience"
                  type="number"
                  value={user.experience}
                  onChange={(e) =>
                    setUser({ ...user, experience: e.target.value })
                  }
                  error={!!errors.experience}
                  helperText={errors.experience}
                  margin="normal"
                  InputLabelProps={{
                    sx: {
                      fontSize: "16px",
                      "@media (max-width: 600px)": {
                        fontSize: "14px",
                      },
                    },
                  }}
                />

                <TextField
                  fullWidth
                  placeholder="Summary of your experience"
                  label="Summary*"
                  name="summary"
                  multiline
                  rows={4}
                  value={user.summary}
                  onChange={(e) =>
                    setUser({ ...user, summary: e.target.value })
                  }
                  error={!!errors.summary}
                  helperText={errors.summary}
                  margin="normal"
                  InputLabelProps={{
                    sx: {
                      fontSize: "16px",
                      "@media (max-width: 600px)": {
                        fontSize: "14px",
                      },
                    },
                  }}
                />
              </div>
            </form>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", py: 3 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handlePrevious}
              style={{ width: "102px" }}
            >
              Previous
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ width: "102px" }}
            >
              Submit
            </Button>
          </Box>
        </Container>
        <Dialog
          open={showConfirm}
          onClose={handleCloseConfirm}
          aria-labelledby="modal-title"
          maxWidth={"xs"}
          fullWidth={true}
        >
          <DialogTitle style={{ paddingBottom: "0px" }} id="modal-title">
            Confirmation
          </DialogTitle>
          <DialogContent style={{ paddingTop: "20px" }}>
            <DialogContentText>
              <div>Are you sure you want to submit.</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseConfirm}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={finalSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
}

export default ExpSummary