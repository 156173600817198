import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { legacy_createStore as createStore } from "redux";
import { ThemeProvider } from '@mui/material/styles';
import App from "./App";
import store from "./store/store";
import theme from './theme'
ReactDOM.render(
  <ThemeProvider theme={theme}>
  <Provider store={createStore(store)}>
    <App />
  </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

