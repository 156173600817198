import React, { useState, useEffect } from "react";
import ServicesData from "../../total_aws_services.json";
import {
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Grid,
  Divider,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Chip from '@mui/material/Chip';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

const SelectService = () => {
  const containerStyle =  {
    height: "100vh",
    backgroundColor: 'white', 
    border: '1px solid #ccc', 
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
    overflowY:"hidden"
  };

  const sidebarStyle = {
    overflowY:"auto",
    borderRight: "1px solid #ccc",
  };

  const contentStyle = {
    overflowY:"auto",
    borderRight: "1px solid #ccc",
  };

  const selectedCheckboxesStyle = {
    overflowY: "auto",
    width: "40%",
    };

  const getCheckbox = useSelector(
    (state) => state.nameReducer.selectedCheckbox
  );
  let {currentPage}=useSelector(state => state.nameReducer);
  const selectedService=useSelector( (state) => state.nameReducer.selectedService)
  const [menuItems, setMenuItems] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    const items = Object.keys(ServicesData).map((key) => ({
      name: key,
      checkboxes: ServicesData[key],
    }));
    setMenuItems(items);
    setSelectedCheckboxes(getCheckbox);
    setSelectedMenuItem(0);
  }, [getCheckbox]);

  useEffect(()=>{
    let data=JSON.parse(localStorage.getItem('surveyData'));
    localStorage.setItem("surveyData",JSON.stringify(
      {user:data.user,selectedCheckbox:selectedCheckboxes,selectedService:data.selectedService,currentPage:data.currentPage}
      ));
    // dispatch({ type: "selected_checkbox", data: selectedCheckboxes });
  },[selectedCheckboxes]);

  const handleNext = () => {
    if (!hasSelectedServices()) {
      setShowModal(true); // Show the modal if no services are selected
      return;
    }
    // console.log("+++++++", services, selectedCheckboxes);
    dispatch({ type: "selected_checkbox", data: selectedCheckboxes });
    dispatch({type:"page",data:currentPage+1})
  };

  const handlePrevious = () => {
    dispatch({ type: "selected_checkbox", data: selectedCheckboxes });
    dispatch({type:"page",data:currentPage-1})
  };

  const handleCheckboxChange = (option) => {
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [option]: !prev[option],
    }));
    let index=selectedService.findIndex((data) => data.service_name === option);
    let list=[...selectedService];
    if(index > -1){
      list.splice(index,1);
    }
    else{
      list.push({service_name:option,rating:"",description:""});
    }
    dispatch({ type: "update_service", data:list})
    // console.log(selectedService);
  };

  const handleDeleteAllServices = () => {
    const updatedCheckboxes = { ...selectedCheckboxes };
    Object.keys(updatedCheckboxes).forEach((key) => {
      updatedCheckboxes[key] = false;
    });
    setSelectedCheckboxes(updatedCheckboxes);
    dispatch({ type: "selected_checkbox", data: []});
    dispatch({ type: "update_service", data: []})
  };

  const handleDeleteService = (option) => {
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [option]: false,
    }));
    let index=selectedService.findIndex((data) => data.service_name === option);
    if(index > -1){
      let list=[...selectedService];
      list.splice(index,1);
      dispatch({ type: "update_service", data: list})
    }
  };

  const hasSelectedServices = () => {
    return Object.values(selectedCheckboxes).some((isChecked) => isChecked);
  };

  return (
    <div>
      <Container
        sx={{
          "@media (max-width: 600px)": {
            padding: "5px",
          },
        }}
      >
        <Typography
          variant={isMobile ? "h6" : "h5"}
          align="center"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          Please select all AWS services you have experience with:
        </Typography>
        <Grid container style={containerStyle}>
          <Grid
            item
            xs={6}
            sm={4}
            spacing={2}
            style={sidebarStyle}
            sx={{
              height: "inherit",
              padding: "16px",
              "@media (max-width: 600px)": {
                padding: "8px",
                paddingTop: "16px",
                textAlign: "center",
                height: "50%",
              },
            }}
          >
            <Typography variant={isMobile ? "h7" : "h5"}>Services:</Typography>
            <List component="nav">
              {menuItems.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => setSelectedMenuItem(index)}
                  selected={selectedMenuItem === index}
                >
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{
                      sx: {
                        fontSize: "16px",
                        "@media (max-width: 600px)": {
                          fontSize: "14px",
                        },
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            style={contentStyle}
            sx={{
              height: "inherit",
              padding: "16px",
              "@media (max-width: 600px)": {
                height: "50%",
                padding: "8px",
                paddingTop: "16px",
                textAlign: "center",
              },
            }}
          >
            {selectedMenuItem !== null && (
              <Container sx={{ padding: 0 }}>
                <Typography variant={isMobile ? "h7" : "h5"}>
                  {menuItems[selectedMenuItem].name}:
                </Typography>
                {menuItems[selectedMenuItem].checkboxes.map((option, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "8px 0",
                      textAlign:"left"
                    }}
                  >
                    <Checkbox
                      color="primary"
                      checked={selectedCheckboxes[option] || false}
                      onChange={() => handleCheckboxChange(option)}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        "@media (max-width: 600px)": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      {option}
                    </Typography>
                  </div>
                ))}
              </Container>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "none",
              "@media (max-width: 600px)": {
                display: "block",
                marginBottom: "10px",
              },
            }}
          >
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              height: "inherit",

              padding: "16px",
              overflowY:"auto",
              "@media (max-width: 600px)": {
                textAlign: "center",
                marginTop: "10px",
                height: "50%",
              },
            }}
            style={selectedCheckboxesStyle}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant={isMobile ? "h7" : "h5"}>
                Selected Services:
              </Typography>
              {hasSelectedServices() && (
                <Chip
                  label="Clear All"
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={handleDeleteAllServices}
                />
              )}
            </div>
            <List>
              {Object.entries(selectedCheckboxes).reverse().map(
                ([option, isChecked], index) =>
                  isChecked && (
                    <ListItem
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <ListItemText primary={option} />
                      <Tooltip title="Clear">
                        <HighlightOffRoundedIcon
                          color="action"
                          style={{ color: "#e06153", cursor: "pointer" }}
                          onClick={() => handleDeleteService(option)}
                        />
                      </Tooltip>
                    </ListItem>
                  )
              )}
            </List>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handlePrevious}
            style={{ width: "102px" }}
          >
            Previous
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleNext}
            style={{ width: "102px" }}
          >
            Next
          </Button>
        </Box>
      </Container>

      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select at least one service before proceeding.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowModal(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectService;
