import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5135EF', // Customize your primary color
    },
    secondary: {
      main: '#f50057', // Customize your secondary color
    },
    // Add more color options as needed
  },
  typography: {
    fontFamily: 'Inter, sans-serif', // Customize your font family
  },
  // Add more theme options and overrides as needed
});

export default theme;