import cloudtechLogo from '../../images/cloudtechLogo.webp'
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';


const Navigation = () => {
  return (
    <div>
      <AppBar position="static" style={{ 
        width: '100vw',
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: 'rgb(255, 255, 255)', 
        // boxShadow: '0px 3px 5px rgba(128, 0, 128, 0.3)', 
        border:'1px solid rgb(234, 237, 242)',
        borderLeft: 'none', 
        borderRight: 'none' 
    }}  elevation={0}>
        <Toolbar>
          <IconButton edge="start" color="primary" aria-label="menu">
            <img src={cloudtechLogo} alt="CloudTech"  style={{ width: '200px' , padding: '8px'}}   />
          </IconButton>
          <Box flexGrow={1}>
          </Box>
          {/* You can add more navigation items here if needed */}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Navigation;

