import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from "@mui/material";

const SurveyForm = () => {
  let userdata=useSelector(state => state.nameReducer.user);
  const {currentPage}=useSelector(state => state.nameReducer);
  const dispatch = useDispatch();
  const [user, setUser] = useState(userdata);
  const [errors, setErrors] = useState({});
  // console.log('start', user)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleNext = (e) => {
    e.preventDefault();
    // console.log('firstPageSubmit', user)
    if (user.name){
      user.name =  user.name.trim()
    }
    
    if (user.location){
      user.location =  user.location.trim()
    }
    if (user.email){
      user.email =  user.email.trim()
    }
    // user.name = user.name.trim()
    // user.location = user.location.trim()
    // user.email = user.email.trim()
    const formErrors = validate(user);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
        dispatch({type:"page",data:currentPage+1})
        dispatch({ type: "userUpdate", data: user})
    }
    
  };

  const validate = (formData) => {
    let formErrors = {};
    const nameRegex = /^[a-zA-Z\s]+$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!formData.name) {
      formErrors.name = "Name is required.";
    } else if (
      !formData.name ||
      !nameRegex.test(formData.name) ||
      formData.name.length < 3
    ) {
      formErrors.name =
        "Name should be only in alphabets and at least 3 characters long";
    }
    if (!formData.location) {
      formErrors.location = "Location is required.";
    }else if (
      !nameRegex.test(formData.location) ||
      formData.location.length < 2
    ) {
      formErrors.location =
        "Country should be only in alphabets and at least 2 characters long";
    }
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!formData.email || !emailRegex.test(formData.email)) {
      formErrors.email = "Email is invalid.";
    }
    return formErrors;
  };

  return (
    <Container
      maxWidth="md"
      style={{ marginTop: "2rem", marginBottom: "2rem" }}
    >
      <Box my={4}>
      <Typography variant={isMobile ? "h6" : "h4"}  fontWeight="bold" component="h3" align="center" gutterBottom>
          AWS Tech Assessment Form
        </Typography>
        <Typography variant="subtitle1" align="center"  component={isMobile ? "h5" : "h3"}>
          Our aim is to establish a pool of talented AWS experts. To achieve this, we have created a form that will help
          us identify our technical strengths.
        </Typography>
      </Box>
      <Box style={{boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',padding:'20px',marginTop:'80px',backgroundColor:'white',
      borderRadius:'16px'}}>
        <Typography variant={isMobile ? "h6" : "h4"} align="center" gutterBottom>
          Fill Your Information
        </Typography>
        <form name="form-signup" id="form-signup" onSubmit={handleNext}>
          <div style={{paddingTop:'10px',paddingBottom:'1px'}}>
          <TextField
            fullWidth
            placeholder="Enter Your Name"
            label="Full Name*"
            name="name"
            value={user.name}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
            error={!!errors.name}
            helperText={errors.name}
            margin="normal"
          />
          </div>
          
          <TextField
            fullWidth
            label="Current Location*"
            name="location"
            placeholder="Enter Your Location"
            value={user.location}
            onChange={(e) => setUser({ ...user, location: e.target.value })}
            error={!!errors.location}
            helperText={errors.location}
            margin="normal"
          >
            {/* <MenuItem value="India">India</MenuItem>
            <MenuItem value="USA">USA</MenuItem>
            <MenuItem value="Canada">Canada</MenuItem> */}
          </TextField>
          <TextField
            fullWidth
            placeholder="Enter Your Email"
            label="Email Id*"
            name="email"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            error={!!errors.email}
            helperText={errors.email}
            margin="normal"
          />
        </form>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleNext}
            style={{width:'102px'}}
          >
            Next
          </Button>
        </Box>
    </Container>
  );
};

export default SurveyForm;
