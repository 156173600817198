import {combineReducers} from "redux";
let data = JSON.parse(localStorage.getItem('surveyData'));

const initialState = {
  currentPage: data?.currentPage ? data?.currentPage : 1,
  existingUser: true,
  page2: '',
  page3: null,
  selectedCheckbox: data?.selectedCheckbox ? data?.selectedCheckbox : [],
  loader: false,
  user: data?.user ? data?.user : {
      name: null,
      location: null,
      email: null,
      experience: null,
      summary: null
  },
  selectedService: data?.selectedService ? data?.selectedService : []
}

const nameReducer = (state = initialState, action) => {


  if (action.type === "selected_checkbox") {
      return {
          ...state,
          selectedCheckbox: action.data
      };
  }
  if (action.type === "new_submission") {
      return {
          ...state,
          page1: '',
          page2: '',
          page3: '',
          selectedCheckbox: ''
      };
  }
  if (action.type === "loader") {
      return {
          ...state,
          loader: action.data
      }
  }
  if (action.type === "userUpdate") {
      localStorage.setItem("surveyData", JSON.stringify({
          user: action.data,
          selectedCheckbox: state.selectedCheckbox,
          selectedService: state.selectedService,
          currentPage: state.currentPage
      }));
      return {
          ...state,
          user: action.data
      }
  }
  if (action.type === "update_service") {
      localStorage.setItem("surveyData", JSON.stringify({
          user: state.user,
          selectedCheckbox: state.selectedCheckbox,
          selectedService: action.data,
          currentPage: state.currentPage
      }));
      return {
          ...state,
          selectedService: action.data
      }
  }
  if (action.type === "page") {
      localStorage.setItem("surveyData", JSON.stringify({
          user: state.user,
          selectedCheckbox: state.selectedCheckbox,
          selectedService: state.selectedService,
          currentPage: action.data
      }));
      return {
          ...state,
          currentPage: action.data
      }
  }
  return state;
};

export default combineReducers({
  nameReducer: nameReducer
});