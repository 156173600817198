import './App.css';
import Navigation from './components/header/header';
import AllComp from './components';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector} from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  const {loader}=useSelector(state => state.nameReducer);
  return (
    <>
    <LoadingOverlay
  active={loader}
  spinner
  text='Loading...'
  >
    <ToastContainer />
    <div style={{position:'fixed',top:'0px'}}>
    <Navigation/>
    </div>
    <div style={{height:'calc(100% - 80px)',top:'78px',width:'100%',position:'fixed'}}>
    <AllComp/>
    </div>
    </LoadingOverlay>
    </>
  );
}


export default App;