import React, { useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from "@mui/material";

const SubmissionSuccess = () => {

    const dispatch = useDispatch();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    useEffect(()=>{
      localStorage.removeItem("surveyData");
       dispatch({ type: "userUpdate" ,data:{ name: null, location: null, email: null} })
       dispatch({type:'selected_checkbox',data:[]})
       dispatch({type:'update_service',data:[]})
    },[])  
    const handleNewSubmission = () => {
    dispatch({type:"page",data:1})
    
  }
      const handleClose = () => {
        dispatch({ type: "new_submission" });
        dispatch({ type: "change_page", data: 1})
        // console.log('dispatched')
      }
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="inherit"
      padding={2}
    >
      <Typography variant={isMobile ? "h6" : "h5"} align="center" gutterBottom>
      Your response has been submitted successfully.
      </Typography>
      
      <Box mt={4}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleNewSubmission}
          style={{ marginRight: 20 }}
        >
          Submit Another Response 
        </Button>
        
        
      </Box>
    </Box>
  );
};

export default SubmissionSuccess;
