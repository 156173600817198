
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux'
import SelectService from './selectService/selectServices';
import SubmissionSuccess from '../components/last-page/lastPage'
import {  Container, Box } from '@mui/material';
import SurveyForm from './survey-form/surveyForm';
import ServiceList from './service-list/serviceList';
import ExpSummary from './experience/experience';
import './index.scss';

const AllComp = () => {
    const {currentPage}=useSelector( (state) => state.nameReducer);
    const containerRef = useRef(null);

    useEffect(() => {
      if (containerRef.current) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        containerRef.current.scrollTo(0, 0);
      }
    }, [currentPage]);

    return (
      <Container maxWidth="xl" style={{height:'100%', overflow:"auto"}} >
      <Box my={4} style={{marginTop:'0px',marginBottom:'0px',height:'100%', overflowY: 'auto'}} ref={containerRef}>
          <div className="form-wrapper" >
            {(currentPage === 1) &&
                <SurveyForm  />     
                }
              {(currentPage === 2) && 
                  <SelectService  />
              }

              {(currentPage === 3) &&
                  <ServiceList  />
              }
              {(currentPage === 4) &&
                  <ExpSummary/>
              }
              {(currentPage === 5) &&
                  <SubmissionSuccess/>
              }
          </div>
      </Box>
  </Container>
    )
}


export default AllComp
